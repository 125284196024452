import {acceptHMRUpdate, defineStore} from 'pinia';
import type {
    PageContent, ActivityState,
    ActivityStateObject,
    ActivityType,
    ContentModule,
    ObserveSubject,
    Slot,
    SlotState, SlotProgressIcon, ProgressIconData
} from '~/types'

import type { SlideType } from '~/components/slideshow/Slideshow.vue'
import {useTeamStore} from './team'
import {useInstructorStore} from './instructor'
import ProgressIcon from "~/components/ProgressIcon.vue";

export const useCourseStore = defineStore('course', {
    // persist: {
    //     storage: sessionStorage,
    // },
    // state: ():{
    //     _config: CourseConfig | Record<string, never>
    // } => {
    //     return {
    //         _config: {}
    //     }
    // },

    getters: {
        // from other stores or getters
        isLastSlot(): boolean | undefined {
            // console.log('isLastSlot', this.schedule, this.activeSlotIdx)
            return this.schedule && this.activeSlotIdx >= this.schedule.slots.length - 1
        },

        schedule(): { slots: Slot[], teams: { activities: ActivityType[] }[] } {
            if (this.isTeam) {
                const teamStore = useTeamStore()
                return teamStore.session.config.schedule;
            } else {
                const instructorStore = useInstructorStore()
                return instructorStore.session.config.schedule;
            }
        },

        content(): { [k: string]: any } {
            if (this.isTeam) {
                const teamStore = useTeamStore()
                return teamStore.session.config.content;
            } else {
                const instructorStore = useInstructorStore()
                return instructorStore.session.config.content;
            }
        },

        isTeam(): boolean {
            const teamStore = useTeamStore()
            return teamStore.session.active
        },

        isInstructorReady(): boolean {
            return this.activeSlotState === 'done'
        },

        activityStateObj(): ActivityStateObject {
            if (this.isTeam) {
                const teamStore = useTeamStore()
                return teamStore.activityStateObj;
            } else {
                const instructorStore = useInstructorStore()
                return instructorStore.activityStateObj
            }
        },

        activityState(): ActivityState {
            return this.activityStateObj?.state
        },

        activityStateIndex(): number { return this.activityStateObj?.index },

        activeSlotState(): SlotState {
            if (this.isTeam) {
                const teamStore = useTeamStore()
                return teamStore.activeSlotState;
            } else {
                const instructorStore = useInstructorStore()
                return instructorStore.activeSlotState
            }
        },

        activeSlotIdx(): number {
            if (this.isTeam) {
                const teamStore = useTeamStore()
                return teamStore.activeSlotIdx;
            } else {
                const instructorStore = useInstructorStore()
                return instructorStore.activeSlotIdx
            }
        },

        activitySlots(): Slot[] {
            if (this.schedule?.slots) {
                return this.schedule?.slots.filter((slot) => slot.type === 'slot');
            } else {
                return [];
            }
        },

        currentSlot(): Slot | undefined {
            if (this.schedule?.slots) {
                return this.schedule.slots[this.activeSlotIdx];
            }
        },

        activityKeys(): ActivityType[] {
            if (this.currentSlot?.type === 'slot') {
                const activitySlotIdx = this.activitySlots?.findIndex((slot: Slot) => slot.id === this.currentSlot?.id)
                return this.schedule.teams.map((team) => (
                    team.activities[activitySlotIdx]
                ));
            } else {
                return []
            }
        },

        contentActivities(): { [k: string]: any } {
            if (this.content) {
                return Object.keys(this.content).filter((key) => this.activityKeys.includes(key as ActivityType)).reduce((cur, key) => {
                    return Object.assign(cur, {[key]: this.content[key]})
                }, {});
            } else {
                return {}
            }
        },

        contentSlotIdx(): number {
            return this.activitySlots.findIndex((slot: Slot) => slot.id === this.currentSlot?.id)
        },

        activityLocations(): string[] | undefined {
            if (!this.currentSlot)
                return []

            if (this.currentSlot?.type === 'slot') {
                // const activitySlotIdx = this.activitySlots.findIndex((slot: Slot) => slot.id === this.currentSlot?.id)
                return this.schedule.teams.map((team) => (
                    this.content[team.activities[this.contentSlotIdx]]?.location
                ));
            }
        },

        teamContent(): {
                type: string, label: string, location: string, hideFromBar?: boolean, images: string[], end: PageContent, modules: ContentModule[], observe?: { text: string, end: PageContent, subjects: ObserveSubject[]}
            } | undefined {
            const teamStore = useTeamStore()

            if (!this.content)
                return undefined

            return this.content[this.activityKeys[teamStore.teamIndex]]
        },

        teamSlides(): SlideType[] | undefined {
            if (this.teamContent?.type === 'mix') {

                if (
                    this.activityStateObj.state === 'modules' && this.teamContent.modules &&
                    this.teamContent.modules[this.activityStateObj.index].type === 'cardGame'
                ) {
                    return this.teamContent.modules[this.activityStateObj.index].intro
                } else if (
                    this.activityStateObj.state === 'modules' && this.teamContent.modules &&
                    this.teamContent.modules[this.activityStateObj.index].type === 'slides'
                ) {
                    return this.teamContent.modules[this.activityStateObj.index].slides
                }

            } else if (this.teamContent?.type === 'observe') {

                if (
                    this.activityStateObj.state === 'observe' && this.teamContent.observe &&
                    this.teamContent.modules[this.activityStateObj.index].type === 'cardGame'
                ) {
                    return this.teamContent.modules[this.activityStateObj.index].intro
                }

            }

            return []
        },

        teamCardGameContent(): ContentModule | undefined {
            return this.teamContent?.modules?.find((module: ContentModule) => module.type === 'cardGame')
        },

        teamObserveContent(): { text: string, subjects: ObserveSubject[]} | undefined {
            return this.teamContent?.observe
        },

        teamObserveSubject(): ObserveSubject | undefined {
            return this.teamContent?.observe?.subjects[this.activityStateIndex]
        },

        teamProgressIcons(): ProgressIconData[] {
            if (!this.isTeam)
                return []

            const teamStore = useTeamStore()
            const teamKeys = this.schedule.teams[teamStore.teamIndex].activities
            const slotProgressIcons: SlotProgressIcon[] = teamKeys.map(( (key, index) => {
                    const activitySlotId = this.activitySlots[index].id
                    const slotIndex = this.schedule.slots.findIndex(slot => slot.id === activitySlotId)
                    return { icons: this.content[key].images?this.content[key].images:'', isChecked: slotIndex < this.activeSlotIdx || (slotIndex === this.activeSlotIdx && (this.activeSlotState === 'done' || this.activityState === 'wait'))};
                }
            ));

            return slotProgressIcons.filter(slotProgressIcon => slotProgressIcon.icons).flatMap(( slotProgressIcon ) => slotProgressIcon.icons.map((icon: string) => {
                    return { icon, isChecked: slotProgressIcon.isChecked }
                })
            )
        },

        instructorContent(): {
            type: string,
            label: string,
            location: string,
            images: string[],
            start: PageContent,
            intro?: SlideType[],
            slides?: SlideType[],
            observe?: any,
            modules?: ContentModule[],
            cardGame?: ContentModule,
            wait?: ContentModule,
            end: PageContent
        } | undefined {
            const instructorKey: ActivityType | undefined = this.activityKeys.find((key) => !key.includes('mix'))

            return instructorKey ? this.content[instructorKey as ActivityType] : undefined
        },

        instructorSlides(): SlideType[] {
            if ((this.instructorContent?.type === 'presentation' || this.instructorContent?.type === 'endgame') && this.instructorContent?.slides) {
                return this.instructorContent.slides
            } else if (this.instructorContent?.type === 'observe') {
                if (this.activityStateObj.state === "observe_subject") {
                    const subjectIndex = this.activityStateObj.index
                    // console.log('instructorSlides', this.activityStateObj.state, subjectIndex)
                    return this.instructorContent?.observe.subjects[subjectIndex].intro
                } else if (this.instructorContent?.intro) {
                    return this.instructorContent?.intro
                }
            }

            return []
        }
    },
    actions: {
        setActivityStateObject(payload: Partial<ActivityStateObject>): void {
            // console.log('xxxxx setActivityStateObject', payload)

            if (this.isTeam) {
                const teamStore = useTeamStore()
                teamStore.setTeamStateObject(payload)
            } else {
                const instructorStore = useInstructorStore()
                instructorStore.setActivityStateObject(payload);

                instructorStore.updateSlotState('started')
            }
        },
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCourseStore, import.meta.hot))
}