export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},{"charset":"utf-8"},{"name":"robots","content":"noindex, nofollow"},{"name":"google-site-verification","content":"JOd9U_9zGinKgCg8V1_uaISy9KiLEhCa4cecTb0E_Bk"}],"link":[],"style":[],"script":[],"noscript":[],"title":"BHV GO","viewport":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0","htmlAttrs":{"lang":"nl"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'