
// by convention, composable function names start with "use"
import type {NuxtSocket} from "nuxt-socket-io";
import type {
    Teams,
    SlotState,
    ActivityStateObject,
    ContentResponse,
    SimpleNoContentResponse,
    Session
} from "~/types";

// import { reactive } from 'vue'
//
// export const store = reactive({
//     count: 0
// })

export const USER_TOKEN_PREFIX = 'USER_TOKEN_';
export const USER_TOKEN_DEV_PREFIX = 'USER_TOKEN_DEV_';

const socket = ref<NuxtSocket>()
const socketStatus = ref({})

const state = reactive<{ connected: boolean, status: {}}>({
    connected: false,
    status: socketStatus
});
export const useInstructorSocket = () => {
    let code = '';

    // Here, we want Nuxt context instead.
    const ctx = useNuxtApp()
    // Setup context:
    // For example, nuxt-socket-io has a built-in
    // teardown feature which will need the onUnmounted hook
    // ctx.onUnmounted = onUnmounted

    // And finally, we can get the socket like before:
    // (instead of "this", it's "ctx" because that's the
    // context here)

    const emitErrors = reactive({});
    const instructorStore = useInstructorStore()

    const registerEvents = (callback?: Function) => {
        if (socket.value?.on) {
            socket.value.on("connect", () => {

                state.connected = true;
                // Store the code/token if the login was succesful
                if (instructorStore.code.length === 0) {
                    instructorStore.setCode(code)
                }
                console.log('on connect + ', state, instructorStore.code, socketStatus)

                sessionList(callback)

            });

            socket.value.on("disconnect", () => {
                console.log('on disconnect + ', state)

                state.connected = false;
                navigateTo('/instructor', { replace: true })
            });

            socket.value.on("connect_error", (err: Error) => {
                console.log('on connect_error + ', err, socketStatus)
                // instructorStore.clearStore()
                state.connected = false;

                // navigateTo('/instructor', { replace: true })
            });

            socket.value.on("sessionTeamsStatusUpdate", (sessionId: number, teams: Teams) => {
                // console.log('on sessionTeamsStatusUpdate + ', teams)
                if (sessionId === instructorStore.session.id) {
                    instructorStore.updateSessionTeamStatuses(teams)
                }
            });

        }
    }

    const socketConnect = (c: string, callback?: Function) => {
        code = c
        if (code.length === 0 && instructorStore.code.length > 0) {
            code = instructorStore.code
        }

        console.log('++++ socketConnect using code', code)

        if (code.length > 0) {
            socket.value = ctx.$nuxtSocket({
                persist: 'instructorDev',
                emitTimeout: 100000, // 1000 ms
                reconnection: true,
                // @ts-ignore
                auth: {
                    token: code
                },
                // transports: ["websocket"],
                statusProp: 'socketStatus'
            })

            registerEvents(callback)
        }
    }
    const sessionCreate = (callback?: Function) => {
        console.log('sio - sessionCreate')

        /* Emit events */
        socket.value?.emit('sessionCreate',
            (resp: ContentResponse<{
                    id: number,
                    secret: string;
                    availableProgramTypes: string[];
                    // teams: {[k: string]: {name: string}};
                }>
            ) => {

            console.log('sessionCreate response', resp)

            /* Handle response, if any */
            if (resp.status === "success") {
                instructorStore.setSession(resp.data)
                instructorStore.setAvailableProgramTypes(resp.data.availableProgramTypes)
            }

            if (callback) {
                callback()
            }
        })
    }

    const sessionList = (callback?: Function) => {
        console.log('sio - sessionList')

        /* Emit events */
        socket.value?.emit('sessionList',
            ( resp: ContentResponse<{
                    sessions: { id: number, secret: string, active: boolean }[]
                }>
            ) => {
                console.log('sessionList response', resp)
                /* Handle response, if any */
                if (resp.status === "success") {
                    if (resp.data.sessions.length === 0) {
                        sessionCreate(callback)
                    } else {
                        instructorStore.setSessions(resp.data.sessions)

                        if (instructorStore.sessions.length > 0) {
                            instructorStore.setSession(instructorStore.lastSession)
                            sessionInfo(callback)
                        }
                    }
                }
            })
    }

    const sessionInfo = (callback?: Function) => {
        console.log('sio - sessionInfo', state.connected)

        socket.value?.emit('sessionInfo', instructorStore.session.secret,
            (resp: ContentResponse<Session>) => {
                console.log('sessionInfo response', resp)
                /* Handle response, if any */
                if (resp.status === "success") {
                    instructorStore.setSession(resp.data)

                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const sessionSelectProgram = (program: string, callback?: Function) => {
        console.log('sio - sessionSelectProgram', state.connected, program)

        socket.value?.emit('sessionSelectProgram', instructorStore.session.secret, program,
            (resp: SimpleNoContentResponse) => {
                console.log('sessionSelectProgram response', resp)

                /* Handle response, if any */
                if (resp.status === 'success') {
                    instructorStore.setProgram(program)

                    sessionInfo(callback)
                }
            })
    }

    const disconnectTeamTablet = (teamId: number) => {
        console.log('sio - disconnectTeamTablet', teamId)

        /* Emit events */
        socket.value?.emit('disconnectTeamTablet', teamId,
            (resp: SimpleNoContentResponse) => {
                /* Handle response, if any */
                console.log('disconnectTeamTablet response', resp)
            })
    }

    const returnToMeetingPoint = (teamId: number, value: boolean) => {
        console.log('sio - returnToMeetingPoint', teamId)

        /* Emit events */
        socket.value?.emit('returnToMeetingPoint', teamId, value,
            (resp: SimpleNoContentResponse) => {
                /* Handle response, if any */
                console.log('returnToMeetingPoint response', resp)
                instructorStore.setTeamReturnToMeetingPointStatus(teamId, value)
            })
    }

    const sessionSetActiveSlot = (index: number, state: SlotState, callback?: Function) => {
        console.log('sio - sessionSetActiveSlot', index, state)

        socket.value?.emit('sessionSetActiveSlot', instructorStore.session.secret, index, state,
            (resp: SimpleNoContentResponse) => {
                /* Handle response, if any */
                console.log('sessionSetActiveSlot response', resp)

                if (resp.status === 'success') {
                    instructorStore.setActiveSlotIdx(index)
                    instructorStore.setActiveSlotState(state)

                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const sessionSetActivityState = (stateObj: ActivityStateObject, callback?: Function) => {
        console.log('sio - sessionSetActivityState', stateObj)

        socket.value?.emit('sessionSetActivityState', instructorStore.session.secret, stateObj,
            (resp: SimpleNoContentResponse) => {
                /* Handle response, if any */

                console.log('sessionSetActivityState response', resp)
                if (resp.status === 'success') {

                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const sessionDelete = (callback?: Function) => {
        console.log('sio - sessionDelete')

        socket.value?.emit('sessionDelete', instructorStore.session.secret,
            (resp: SimpleNoContentResponse) => {
                /* Handle response, if any */

                console.log('sessionDelete response', resp)
                if (resp.status === 'success') {

                    instructorStore.clearStore()
                    state.connected = false;

                    socket.value?.disconnect()
                    
                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const sessionReset = () => {
        state.connected = false;
        instructorStore.clearStore();
        socket.value?.disconnect()
    }

    return { sessionReset, socketConnect, socket, state, socketStatus, emitErrors, sessionCreate, sessionDelete, sessionList, sessionInfo, sessionSelectProgram, disconnectTeamTablet, returnToMeetingPoint, sessionSetActiveSlot, sessionSetActivityState } ;
}


