export default defineNuxtRouteMiddleware((to, from) => {
    const { $instructorSocket } = useNuxtApp()

    if (to.path.startsWith('/instructor') && !to.path.includes('/login') && !to.path.includes('/reconnecting')) {

        console.log('connected check - instructor 1', $instructorSocket.state)
        if (!$instructorSocket.state.connected) {
            const instructorStore = useInstructorStore()

            // console.log('connected check - instructor 2', instructorStore.secret, instructorStore.code)

            if (instructorStore.secret || instructorStore.code) {
                console.log('to /instructor/bootstrap/reconnecting')
                return navigateTo('/instructor/bootstrap/reconnecting', { replace: true })
            } else {
                // console.log('to /instructor/bootstrap/enterCode')
                return navigateTo('/instructor/bootstrap/login', { replace: true })
            }



        }
    }
})