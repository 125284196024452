export default defineNuxtRouteMiddleware((to, from) => {
    const { $teamSocket } = useNuxtApp()

    if (to.path.startsWith('/team')) {
        // console.log('connected check - team: ', $teamSocket.state, to.path)
        if ($teamSocket.state.inSession || to.path.includes('/reconnecting') || to.path.includes('/enterCode')) {
            return
        }

        const teamStore = useTeamStore()
        console.log('connected check - team: ', $teamSocket.state, to.path, teamStore.instructorSecret,  teamStore.teamSecret)

        // check to see if we can reconnect using some secret
        if (!$teamSocket.state.connected && (teamStore.teamSecret.length !== 0 || teamStore.instructorSecret.length !== 0)) {
            console.log('to /team/bootstrap/reconnecting')
            return navigateTo('/team/bootstrap/reconnecting', { replace: true })

        } else if (!$teamSocket.state.inSession && (teamStore.instructorSecret.length === 0 && teamStore.teamSecret.length === 0)) {
            // if we don't have any the instructor needs to enter a code
            // console.log('to /team/bootstrap/enterCode')
            return navigateTo('/team/bootstrap/enterCode', { replace: true })
        }
    }
})