// added for storybook
import {defineStore, acceptHMRUpdate} from 'pinia'
import type {
    ActivityStateObject,
    Member,
    SlotState,
    Team,
    Teams,
    Session
} from "~/types";
import { useCourseStore } from "~/stores/course";
const hasDevUrl = window.location.href.includes('localhost')
const ss = sessionStorage.getItem("ss") === "enabled";

export const useTeamStore = defineStore('team', {
    persist: {
        storage: hasDevUrl || ss ? sessionStorage:localStorage,
        // afterRestore: (ctx) => {
        //     console.log(`just restored '${ctx.store.$state._session.id}'`)
        // },
        // paths: ['_session']
        key: 'g4s-session',
    },
    state: ():{
        _instructorSecret: string
        _currentTeam: Team
        _session: Session
        _teamState: ActivityStateObject
    } => {
        return {
            _instructorSecret: '',
            _currentTeam: {
                id: -1,
                name: '',
                secret: '',
                members: {}
            },
            _session: {
                id: -1,
                secret: '',
                active: false,
                program: '',
                config: {},
                activeSlotIdx: 0,
                activeSlotState: 'start',
                activityState: { state: 'start', index: -1 },
                teams: {},
            },
            _teamState: {
                state: 'none',
                index: -1
            }
        }
    },

    getters: {
        // from state
        session: (state): Session => { return state._session; },
        teamState: (state): ActivityStateObject => { return state._teamState; },
        currentTeam: (state): Team => { return state._currentTeam; },
        instructorSecret: (state): string => { return state._instructorSecret; },

        // from getters
        activeSlotIdx(): number { return this.session.activeSlotIdx },
        activeSlotState(): SlotState { return this.session.activeSlotState },
        activityStateObj(): ActivityStateObject {
            if (this.isTeamWithInstructor && this.teamState.state === 'none') {
                return this.session.activityState as ActivityStateObject;
            } else {
                return this.teamState as ActivityStateObject;
            }
        },
        program(): string { return this.session.program; },
        teams(): Teams { return this.session.teams },
        members(): Member[] {
            if (this.currentTeam.members) {
                return Object.values(this.currentTeam.members);
            }
            else {
                return []
            }
        },
        teamSetupComplete(): boolean  { return this.currentTeam.setupComplete as boolean },
        teamSecret(): string { return this.currentTeam.secret },
        teamName(): string { return this.currentTeam.name },
        teamId(): number { return this.currentTeam.id },
        returnToMeetingPoint(): boolean { return this.currentTeam.returnToMeetingPoint || false },
        teamIndex(): number { return Object.values(this.session.teams).findIndex((team) => team.id == this.currentTeam.id) },
        isTeamWithInstructor(): boolean {
            const courseStore = useCourseStore()
            return courseStore.teamContent?.type !== 'mix'
        },
    },

    actions: {
        setActivityStateObject(payload: Partial<ActivityStateObject>): void {
            // console.log('--------------- setActivityStateObject', payload)

            if (!this._session.activityState) {
                this._session.activityState = {
                    state: "none",
                    index: -1
                }
            }

            Object.assign(this._session.activityState, payload);
        },
        initTeamState(payload: ActivityStateObject, callback?: Function): void {
            // console.log('--------------- setTeamState', payload)

            if (payload) {
                this._teamState = payload
            }
        },
        setTeamStateObject(payload: Partial<ActivityStateObject>, callback?: Function): void {
            // console.log('--------------- setTeamState', payload)

            const { $teamSocket } = useNuxtApp()

            if (!this._teamState) {
                this._teamState = { state: 'none', index: -1}
            }

            Object.assign(this._teamState, payload);

            $teamSocket.teamSetState(this._teamState)
        },

        setActiveSlotState(payload: SlotState): void {
            // console.log('++++ setActiveSlotState', payload)
            this._session.activeSlotState = payload;
        },
        setActiveSlotIdx(payload: number): void {
            // console.log('++++ setActiveSlotIdx', payload)
            this._session.activeSlotIdx = payload;

            // reset teamStateObject, so we don't remember the state from the previous activity
            // this.setTeamStateObject({ state: 'none', index: -1})
        },
        setSession(payload: Partial<Session>) {
            // console.log('---------------setSession', payload, this._session)

            // if (!this._session) {
            //     this._session = {}
            // }

            Object.assign(this._session, payload);
            Object.values(this._session.teams).forEach((team: Team) => {
                if (this._currentTeam.id === team.id) {
                    console.log('setSession -  update currentTeam', team.id)
                    this.setCurrentTeam(team)
                }
            })
            // console.log('---------------session set', payload, this.session)
        },
        setTeamSetupComplete(payload: boolean) {
            // console.log('---------------setTeamSetupComplete', payload)
            this._currentTeam.setupComplete = payload;
        },
        setCurrentTeam(payload: Partial<Team>) {
            console.log('---------------setCurrentTeam', payload)
            Object.assign(this._currentTeam, payload);
        },
        setReturnToMeetingPoint(payload: boolean) {
            this._currentTeam.returnToMeetingPoint = payload;
        },
        setTeamAsLinked(payload: number) {
            // console.log('---------------setTeamAsLinked', payload)
            const team = this._session.teams[payload].linked = true
        },
        setInstructorSecret(payload: string) {
            // console.log('setSecret', payload)
            this._instructorSecret = payload
        },
        addMember(payload: Member) {
            if (this._currentTeam.members && payload.id)
                this._currentTeam.members[payload.id] = payload
        },
        removeMember(payload: number) {
            if (this._currentTeam.members && this._currentTeam.members[payload])
                delete this._currentTeam.members[payload]
        },
        clearStore () {
            // console.log('clear team store!!!!!!!!')
            this.$reset()
        }
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTeamStore, import.meta.hot))
}