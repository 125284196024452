import {useInstructorSocket} from "~/composables/instructorSocket";
import {useTeamSocket} from "~/composables/teamSocket";

export default defineNuxtPlugin( ({}) => {
    console.log('defineNuxtPlugin - $instructorSocket & $teamSocket')

    const instructorSocket = useInstructorSocket()
    const teamSocket = useTeamSocket()

    return {
        provide: {
            instructorSocket,
            teamSocket
        }
    }
})