// added for storybook
import {defineStore, acceptHMRUpdate} from 'pinia'

import type {Session, Team, Teams, SlotState, ActivityStateObject, LocationTeam} from "~/types";
import {useCourseStore} from "~/stores/course";
const hasDevUrl = window.location.href.includes('localhost')
const ss = sessionStorage.getItem("ss") === "enabled";

export const useInstructorStore = defineStore('instructor', {
    persist: {
        storage: hasDevUrl || ss ? sessionStorage:localStorage,
        // afterRestore: (ctx) => {
        //     console.log(`just restored '${ctx.store.$state._session.id}'`)
        // },
        // paths: ['_code', '_session']
    },
    state: () : {
        _code: string;
        _session: Session;
        _sessions: Partial<Session>[];
        _availableProgramTypes: string[];
    } => {
        return {
            _code: '',
            _session: {
                id: -1,
                active: false,
                program: '',
                secret: '',
                teams: {},
                config: {},
                activeSlotIdx: 0,
                activeSlotState: 'started',
                activityState: {
                    state: 'start',
                    index: -1
                }
            },
            _sessions: [],
            _availableProgramTypes: []
        }
    },

    getters: {
        // from state
        code: (state): string => { return state._code; },
        session: (state) => { return state._session; },
        sessions: (state) => { return state._sessions; },
        availableProgramTypes: (state) => { return state._availableProgramTypes; },

        // from getters
        activeSlotIdx(): number { return this.session.activeSlotIdx; },
        activeSlotState(): SlotState { return this.session.activeSlotState; },
        activityStateObj(): ActivityStateObject { return this.session.activityState as ActivityStateObject; },

        program(): string { return this.session.program; },
        lastSession(): Partial<Session> { return this.sessions[this.sessions.length-1] },
        secret(): string { return this.session?.secret; },
        teams(): Team[] { return Object.values(this.session.teams) as Team[] },
        locationTeams(): LocationTeam[] {
            const locationTeams = this.teams.map(
                (team, index) => {
                    const courseStore = useCourseStore()
                    return {
                        ...team,
                        location: courseStore.activityLocations?.[index]
                    }
                })

            return locationTeams
        },
        countLinkedTeams(): number {
            return this.teams.filter( (team) => team.linked).length;
        },
        countTeamsReadyToStartCourse() : number {
            return this.teams.filter( (team) => team.setupComplete).length;
        },
        countTeamsWithActiveSlotComplete() : number {
            return this.teams.filter( (team) => team.activeSlotComplete).length;
        },
        areTeamsOnAnotherLocation() : boolean {
            const courseStore = useCourseStore()
            return courseStore.activityKeys.some((key) => key.includes('mix'))
        },
        canInstructorContinue() : boolean {
            const courseStore = useCourseStore()
            if (courseStore.activityKeys.some((key) => key === 'intro'))
                return this.sessions.length > 0 && this.activeSlotIdx <= 1 && this.activityStateObj?.state !== 'end' || this.countTeamsReadyToStartCourse === 4
            else if (this.areTeamsOnAnotherLocation)
                return this.countTeamsWithActiveSlotComplete === 2
            else
                return true
        }
    },

    actions: {
        startCourse(callback: Function): void {
            const { $instructorSocket } = useNuxtApp()
            $instructorSocket.sessionSetActiveSlot(1, 'start', ()=> {
                this._session.active = true;

                this.setActivityStateObject({ state: 'start' }, callback)
            });
        },

        updateSlotState(state: SlotState, callback?: Function): void {
            const { $instructorSocket } = useNuxtApp()
            $instructorSocket.sessionSetActiveSlot(this._session.activeSlotIdx, state, ()=> {
                console.log('activeSlot state is done')
                if (callback)
                    callback()
            });
        },

        completeSlot(callback?: Function): void {
            const { $instructorSocket } = useNuxtApp()
            $instructorSocket.sessionSetActiveSlot(this._session.activeSlotIdx, 'done', ()=> {
                // console.log('activeSlot state is done')
                if (callback)
                    callback()
            });
        },

        nextSlot(callback?: Function): void {
            const nextSlotIndex = this._session.activeSlotIdx + 1
            const courseStore = useCourseStore()
            const { $instructorSocket } = useNuxtApp()
            $instructorSocket.sessionSetActiveSlot(nextSlotIndex, 'start', ()=> {
                this.resetTeamActiveSlotComplete()

                if (courseStore.currentSlot?.type === 'slot')
                    this.setActivityStateObject({ state: courseStore.instructorContent?.type === 'observe' ? 'preStart' : 'start' }, callback)
                else
                    this.setActivityStateObject({ state: courseStore.currentSlot?.type }, callback)

            });
        },

        setActiveSlotIdx(payload: number): void {
            // console.log('---- setActiveSlotIdx', payload)
            this._session.activeSlotIdx = payload;
        },

        setActiveSlotState(payload: SlotState): void {
            // console.log('---- setActiveSlotState', payload)
            this._session.activeSlotState = payload;
        },

        setActivityStateObject(payload: Partial<ActivityStateObject>, callback?: Function): void {
            // console.log('---- setActivityStateObject', payload)
            const { $instructorSocket } = useNuxtApp()
            // this._session.activityState.state = payload.state?? this._session.activityState.state
            // this._session.activityState.index = payload.index?? 0;
            //

            if (!this._session.activityState) {
                this._session.activityState = {
                    state: "none",
                    index: -1
                }
            }

            Object.assign(this._session.activityState, payload);
            // console.log('set Instructor ActivityState', payload, this._session.activityState)

            // update server kept state for continuing later
            $instructorSocket.sessionSetActivityState(this._session.activityState, callback)
        },

        setSessions(payload: Partial<Session>[]) {
            // console.log('setSessions', payload)
            this._sessions = payload
        },

        setAvailableProgramTypes(payload: string[]) {
            this._availableProgramTypes = payload;
        },

        setSession(payload: Partial<Session>) {
            const courseStore = useCourseStore()
            // console.log('setSession', payload)

            Object.assign(this._session, payload);
        },

        updateSessionTeamStatuses(payload: Teams) {
            Object.values(payload).forEach((team: Partial<Team>) => {
                // console.log('updateSessionTeamStatuses', team.id, team.activeSlotComplete)
                this._session.teams[team.id as number].linked = team.linked
                this._session.teams[team.id as number].setupComplete = team.setupComplete
                this._session.teams[team.id as number].activeSlotComplete = team.activeSlotComplete
            })
        },

        resetTeamActiveSlotComplete() {
            Object.values(this._session.teams).forEach((team: Team) => {
                // console.log('resetTeamStates', team.id, team.activeSlotComplete)
                this._session.teams[team.id as number].activeSlotComplete = false
            })
        },

        setTeamReturnToMeetingPointStatus(teamId: number, value: boolean) {
            this._session.teams[teamId as number].returnToMeetingPoint = value
        },

        setCode(payload: string) {
            // console.log('setCode', payload)
            this._code = payload
        },

        setProgram(payload: string) {
            // console.log('setProgram', payload)
            this._session.program = payload
        },

        clearStore () {
            this.$reset()
        }
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useInstructorStore, import.meta.hot))
}