import { useWakeLock } from '@vueuse/core'
import * as vue_demi from "vue-demi";

export default defineNuxtPlugin( ({}) => {
    console.log('defineNuxtPlugin - $wakeLock')

    const wakeLock: {
        isSupported: vue_demi.ComputedRef<boolean>;
        isActive: vue_demi.Ref<boolean>;
        request: (type: WakeLockType) => Promise<void>;
        release: () => Promise<void>;
    } = reactive(useWakeLock())

    if (wakeLock.isSupported)
        wakeLock.request('screen')

    return {
        provide: {
            wakeLock
        }
    }
})