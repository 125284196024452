import { acceptHMRUpdate } from 'pinia'
import type {ObserveSubject, ObserveState, ObserveCheck, Member} from '~/types'
import {useCourseStore} from "~/stores/course";
import {shuffle} from "pandemonium";

export const useObserveStore = defineStore('observe', {
    persist: {
        storage: sessionStorage,
        // afterRestore: (ctx) => {
        //     console.log(`just restored '${ctx.store.$state._session.id}'`)
        // },
        // paths: ['_session']
        key: 'g4s-observe',
    },
    state: ():{
        _state: ObserveState,
        _round: number,
    } => {
        return {
            _state: 'start',
            _round: 0,
        }
    },
    getters: {
        // from state
        state: (state): ObserveState => {
            return state._state;
        },
        round: (state): number => {
            return state._round;
        },

        // from getters and other stores
        observeSubject(): ObserveSubject | undefined {
            const courseStore = useCourseStore()
            return courseStore.teamObserveSubject
        },

        observeSubjectIndex(): number | undefined {
            const courseStore = useCourseStore()
            return courseStore.activityStateIndex
        },

        shuffledMembers(): Member[] {
            const teamStore = useTeamStore()
            const members = teamStore.members
            let shuffledMembers = shuffle(members)

            if (this.observeSubjectIndex) {
                shuffledMembers = shuffle(members)
            }

            return shuffledMembers
        },

        observeAvatarRoles(): { [role: string]: string } {
            const teamStore = useTeamStore()
            const members = this.shuffledMembers
            const noMembers = members.length
            let memberRoles: { [role: string]: string } = {}
            let availableRoles = ['executor', 'observer']

            if (this.observeSubject?.hasVictim) {
                availableRoles.push('victim')
            }

            for (let roleIndex = 0; roleIndex < availableRoles.length; roleIndex++) {
                const role = availableRoles[roleIndex]
                const memberIndex = (this.round - 1 + roleIndex) % noMembers
                const member = members[memberIndex]

                // Als er niet genoeg deelnemers zijn voor alle rollen, laat de laatste deelnemer de ro			l van uitvoerder spelen
                if (this.observeSubject?.hasVictim && noMembers < availableRoles.length && role === 'observer') {
                    memberRoles[role] = member.avatar
                    memberRoles['victim'] = member.avatar
                    console.log(`${member.avatar} speelt rol: ${role} en slachtoffer`)
                    break;
                } else {
                    memberRoles[role] = member.avatar
                    console.log(`${member.avatar} speelt rol: ${role}`, noMembers, availableRoles.length)
                }
            }

            return memberRoles
        },

        observeRandomChecks(): ObserveCheck[] | undefined {
            if (this.observeSubject?.practice?.checks) {
                const randomChecks = shuffle(this.observeSubject?.practice?.checks)
                // this statement makes sure every round has a random list of checks
                if (this.round) {
                    return randomChecks.slice(0,3).sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                }
            }
            return undefined
        }
    },
    actions: {
        startNewSubject() {
            this._state = 'start'
            this._round = 1
        },
        setState(payload: ObserveState) {
            this._state = payload
        },
        setRound(payload: number) {
            this._round = payload
        },
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useObserveStore, import.meta.hot))
}