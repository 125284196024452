import validate from "/home/jenkins/workspace/g4s_bhv_app/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45connected_45instructor_45global from "/home/jenkins/workspace/g4s_bhv_app/frontend/middleware/01.connected.instructor.global.ts";
import _01_45connected_45team_45global from "/home/jenkins/workspace/g4s_bhv_app/frontend/middleware/01.connected.team.global.ts";
import _02_45bootstrap_45instructor_45global from "/home/jenkins/workspace/g4s_bhv_app/frontend/middleware/02.bootstrap.instructor.global.ts";
import _02_45bootstrap_45team_45global from "/home/jenkins/workspace/g4s_bhv_app/frontend/middleware/02.bootstrap.team.global.ts";
import manifest_45route_45rule from "/home/jenkins/workspace/g4s_bhv_app/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45connected_45instructor_45global,
  _01_45connected_45team_45global,
  _02_45bootstrap_45instructor_45global,
  _02_45bootstrap_45team_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}