import {defineStore, acceptHMRUpdate} from 'pinia'
import type {
    CardGameQuestion,
    CardGameRound,
    CardGameRoundSubType,
    CardGameState,
    CardGameSubRound,
    ContentModule
} from "~/types";
import {shuffle} from "pandemonium";
import {useCourseStore} from "~/stores/course";

export const useCardGameStore = defineStore('cardGame', {
    persist: {
        storage: sessionStorage,
        // afterRestore: (ctx) => {
        //     console.log(`just restored '${ctx.store.$state._session.id}'`)
        // },
        // paths: ['_session']
        key: 'g4s-cardGame',
    },
    state: ():{
        _state: CardGameState,
        _round: number,
        _roundSubType: CardGameRoundSubType,
        _questionIndex: number,
        _questionsShuffled: CardGameQuestion[],
        _demoMode: boolean
    } => {
        return {
            _state: 'start',
            _round: -1,
            _roundSubType: 'normal',
            _questionIndex: -1,
            _questionsShuffled: [],
            _demoMode: false
        }
    },
    getters: {
        // from state
        state: (state): CardGameState => {
            return state._state;
        },
        subRoundType: (state): CardGameRoundSubType => {
            return state._roundSubType
        },
        questionIndex: (state): number => {
            return state._questionIndex
        },
        demoMode: (state): boolean => {
            return state._demoMode
        },

        // from getters
        contentCardGame(): ContentModule | undefined {
            const courseStore = useCourseStore()
            return courseStore.teamCardGameContent
        },

        round(): CardGameRound | undefined {
            return this.contentCardGame?.cardGame?.rounds[this._round];
        },

        questions(): CardGameQuestion[] | [] {
            if (this.round)
                return this.round?.questions
            else
                return []
        },

        currentQuestion(): CardGameQuestion | undefined {
            return this._questionsShuffled[this._questionIndex]
        },

        subRound(): CardGameSubRound | undefined {
            if (this.round)
                return this.round[this._roundSubType]
            else
                return undefined
        },

        nextRoundIndex(): number {
            return this._round + 1
        },

        isSubRoundFinished(): boolean {
            return this.questions.length - 1 <= this._questionIndex
        },

        isQuizFinished(): boolean {
            if (this.contentCardGame?.cardGame?.rounds)
                return this.isSubRoundFinished && this.contentCardGame?.cardGame?.rounds?.length - 1 === this._round && this._roundSubType === 'onscreen'
            else
                return false
        }
    },
    actions: {
        startNextRound(callback: Function): void {
            console.log('startNextRound', this._round)

            this.setState('countdown')
            this.setRoundSubType('normal')

            this.setQuestionIndex(0)
            this._round = this._round + 1
            this.shuffleQuestions()

            if (callback)
                callback()
        },

        finishQuiz(callback: Function): void {
            console.log('finishQuiz')

            this.setState('finished')
            this.setRoundSubType('normal')

            this.setQuestionIndex(0)
            this._round = -1

            if (callback)
                callback()
        },

        nextQuestion(callback: Function): void {
            console.log('nextQuestion', this._questionIndex)

            this.setState('countdown')
            this.setQuestionIndex(this._questionIndex + 1)

            if (callback)
                callback()
        },

        nextSubRound(callback: Function): void {
            console.log('nextSubRound', this._questionIndex)

            // shuffle questions again
            this.shuffleQuestions()

            switch (this._roundSubType) {
                case "normal":
                    this.setRoundSubType('faster')
                    break;

                case "faster":
                    this.setRoundSubType('onscreen')
                    break;
            }

            this.setState('bumper')
            this.setQuestionIndex(0)

            if (callback)
                callback()
        },

        shuffleQuestions(): void {
            console.log('shuffleQuestions')

            this._questionsShuffled = shuffle(this.questions)
        },

        setState(payload: CardGameState):void {
            this._state = payload
        },

        setQuestionIndex(payload: number):void {
            this._questionIndex = payload
        },

        setRoundSubType(payload: CardGameRoundSubType):void {
            this._roundSubType = payload
        },

        enableDemoMode(): void {
            this._demoMode = true
        },

        clearStore () {
            console.log('clear cardGame store!!!!!!!!')
            this.$reset()
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCardGameStore, import.meta.hot))
}