export default defineNuxtRouteMiddleware((to, from) => {
    const instructorStore = useInstructorStore()
    // console.log('bootstrap check', to.path, to.path.startsWith('/instructor'), !to.path.includes('/bootstrap'), instructorStore.session.active)
    if (to.path.startsWith('/instructor') && !to.path.includes('/bootstrap') && !instructorStore.session.active) {
        // console.log('bootstrap check', to.path)
        if (instructorStore.program.length === 0 && !to.path.includes('/selectProgram')) {
            // console.log('bootstrap check - instructor program', to.path)
            return navigateTo('/instructor/bootstrap/selectProgram', { replace: true })
        } else if (!instructorStore.session.active && !to.path.includes('/teams')) {
            // console.log('bootstrap check - instructor teams', instructorStore.countLinkedTeams, to.path, 'send to teams')
            return navigateTo('/instructor/bootstrap/linkTeams', { replace: true })
        }
    }
})