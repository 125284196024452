import {acceptHMRUpdate} from 'pinia'

export const useSlideshowStore = defineStore('slideshow', {
    persist: {
        storage: sessionStorage,
        // afterRestore: (ctx) => {
        //     ctx.store.$state._startTime = new Date(ctx.store.$state._startTime)
        //     console.log(`just restored '${ctx.store.$state._startTime}'`)
        // },
        // paths: ['_session']
        key: 'g4s-slideshow',
    },
    state: (): {
        _slideIndex: number,
    } => {
        return {
            _slideIndex: 0
        }
    },
    getters: {
        // from state
        slideIndex: (state): number => {
            return state._slideIndex;
        }
    },

    actions: {
        setIndex(payload: number) {
            this._slideIndex = payload
        },

        resetIndex () {
            this.$reset()
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSlideshowStore, import.meta.hot))
}