export default defineNuxtRouteMiddleware((to, from) => {
    const teamStore = useTeamStore()

    if (to.path.startsWith('/team') && !to.path.includes('/bootstrap')) {
        const { $teamSocket } = useNuxtApp()
        // console.log('bootstrap check', to.path, $teamSocket.state.inSession, teamStore.teamSecret.length)

        if ($teamSocket.state.connected && teamStore.teamSecret.length === 0 && !to.path.includes('/selectTeam')) {
            // console.log('bootstrap check - team session', to.path)
            return navigateTo('/team/bootstrap/selectTeam', { replace: true })
        } else if (!teamStore.teamSetupComplete && !to.path.includes('/members')) {
            console.log('bootstrap check - team members', to.path, teamStore)
            return navigateTo('/team/members', { replace: true })
        }
    }
})