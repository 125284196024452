
// by convention, composable function names start with "use"
import type {NuxtSocket} from "nuxt-socket-io";
import type {
    Member,
    SlotState,
    ActivityStateObject,
    ContentResponse,
    Session,
    CourseConfig,
    SimpleNoContentResponse
} from "~/types";

const socket = ref<NuxtSocket>()

const state = reactive({
    connected: false,
    inSession: false,
    reconnectAttempts: 0
});

const rateLimitMessage = 'Maximum aantal verbindingspogingen bereikt, probeer later nog eens';

export const useTeamSocket = () => {
    // Here, we want Nuxt context instead.
    const ctx = useNuxtApp()
    // Setup context:
    // For example, nuxt-socket-io has a built-in
    // teardown feature which will need the onUnmounted hook
    // ctx.onUnmounted = onUnmounted

    // And finally, we can get the socket like before:
    // (instead of "this", it's "ctx" because that's the
    // context here)


    const teamStore = useTeamStore()
    const cardGameStore = useCardGameStore()
    const courseStore = useCourseStore()

    const teamSecret = ref<string>('')
    teamSecret.value = teamStore.teamSecret

    const socketConnect = (callback?: Function) => {

        teamSecret.value = teamStore.teamSecret
        console.log('socketConnect', teamSecret.value)

        socket.value?.removeAllListeners()
        socket.value?.disconnect()

        socket.value = (teamSecret.value?.length > 0) ?
            ctx.$nuxtSocket({
                persist: 'team',
                reconnection: true,
                emitTimeout: 100000, // 1000 ms
                // @ts-ignore
                auth: {
                    token: `TEAM_TOKEN_${teamSecret.value}`
                },
                // transports: ["websocket"]
            })
            :
            ctx.$nuxtSocket({
                reconnection: false,
                emitTimeout: 1000, // 1000 ms
                // transports: ["websocket"]
            })


        socket.value?.on("connect", () => {
            state.connected = true

            // state.reconnectAttempts = 0

            if (teamSecret.value?.length > 0) {
                // console.log('connect - ', state, teamSecret.value)
                state.inSession = true
                if (callback) {
                    callback()
                }
            }
            //todo: is dit nodig indien de teamSecret al bekend is?
             else if (teamStore.instructorSecret.length !== 0) {
                 sessionInfo(teamStore.instructorSecret, callback)
             }

        });

        socket.value.on("disconnect", () => {
            console.log('disconnect - ', state)

            state.connected = false
            state.inSession = false

            navigateTo('/team/bootstrap/reconnecting', { replace: true })
        });

        socket.value.on("disconnectFromSession", () => {
            console.log('disconnectFromSession - ', state)

            state.connected = false;
            teamStore.clearStore();
            socket.value?.disconnect()
        });

        socket.value.on("returnToMeetingPoint", (resp: boolean) => {
            console.log('returnToMeetingPoint - ', resp)
            teamStore.setReturnToMeetingPoint(resp)
        });

        socket.value.on("updateActiveSlot", (index: number, state: SlotState) => {
            console.log('updateActiveSlot - ', index, state)

            const slotChanged = index > teamStore.activeSlotIdx

            teamStore.setActiveSlotIdx(index)
            teamStore.setActiveSlotState(state)

            if (state === 'done' && teamStore.isTeamWithInstructor && courseStore.teamContent?.type === 'observe') {
                teamStore.setTeamStateObject({state: 'none', index: -1})
                navigateTo('/team/end', { replace: true })
            } else if (slotChanged) { // important for mix modules, to be rest after they were in wait
                teamStore.setTeamStateObject({state: 'none', index: -1})
                navigateTo('/team', { replace: true })

            }

            // this session should be active by now, update sessionInfo to check.
            if (!teamStore.session.active) {
                sessionInfo(teamStore.instructorSecret)
            }
        });

        socket.value.on("activityStateUpdate", (resp: Partial<ActivityStateObject>) => {
            console.log('activityStateUpdate - ', resp)

            teamStore.setActivityStateObject(resp)

            switch (resp.state) {
                // to location
                case 'preStart':
                    // don't go anywhere... if you're not there yet, you're busy with team edits
                    break;

                // to location
                case 'location':
                    // to make sure the other sets it's team state and has control
                    if (!teamStore.isTeamWithInstructor)
                        teamStore.setTeamStateObject(resp)

                    navigateTo('/team/toLocation', { replace: true })
                    break;

                // to active observe
                case 'observe_subject':
                case 'observe':
                    if (teamStore.isTeamWithInstructor && courseStore.teamContent?.type === 'observe') {
                        const observeStore = useObserveStore()
                        console.log('resp.index', resp.index, 'observeStore.state', observeStore.state)
                        if (resp.index === -1 && observeStore.state === 'end') {
                            observeStore.setState('done')
                            // navigateTo(`/team`, {replace: true})
                        } else if (resp.state === 'observe' && resp.index !== undefined && resp.index > -1) {
                            teamStore.setTeamStateObject({state: 'observe', index: resp.index})
                            observeStore.startNewSubject()
                            navigateTo(`/team/observe`, {replace: true})
                        }
                    }
                    break;

                case 'end':
                    console.log('end state', courseStore.teamContent?.type, teamStore.isTeamWithInstructor)
                    if (teamStore.isTeamWithInstructor && courseStore.teamContent?.type === 'endgame') {
                        navigateTo(`/team/end`, {replace: true})
                    }
                    break;

                // back to home ...
                default:
                    if (teamStore.isTeamWithInstructor && courseStore.teamContent?.type === 'observe'
                        && resp.state !== 'start' && resp.state !== 'intro')
                        navigateTo(`/team`, { replace: true })
                    break;
            }
        });

        socket.value.on("sessionDeleted", (err: Error) => {
            // console.log('---- sessionDeleted + ', err)

            state.connected = false;
            teamStore.clearStore();

            // don't remember quiz data after finishing/deleteing session

            cardGameStore.clearStore();
            socket.value?.disconnect()
        });

        socket.value.on("connect_error", (err: Error) => {
            console.log('---- connect_error + ', err)
            // instructorStore.clearStore()
            state.connected = false;
            // state.reconnectAttempts++


            // what to do if we can't reconnect quick enough?
            // if (state.reconnectAttempts > 30) {
            //     console.log('---- connect attempts: ', state.reconnectAttempts, callback)
            //
            //     teamStore.clearStore()
            //     state.reconnectAttempts = 0
            //
            //     if (callback) {
            //         callback()
            //     }
            // }
        });
    }

    const sessionReset = () => {
        state.connected = false;
        teamStore.clearStore();
        socket.value?.disconnect()
    }

    const sessionJoin = (id: number, name: string, callback?: Function) => {
        // console.log('sio - sessionJoin', id, name, teamStore.instructorSecret)

        /* Emit events */
        socket.value?.emit('sessionJoin', teamStore.instructorSecret, id,
            (resp: ContentResponse<{
                team: {
                    setupComplete: boolean,
                    secret: string,
                    members: { [k: number]: { avatar: string } }
                },
                config: CourseConfig
            }>) => {
                console.log('sessionJoin response', resp)

                /* Handle special case 'ratelimit' (temp solution Simon) */
                if (resp.status === "ratelimit") {
                    alert(rateLimitMessage);
                }

                /* Handle response, if any */
                if (resp.status === "success") {

                    teamGetState()

                    teamSecret.value = resp.data.team.secret
                    teamStore.setCurrentTeam({
                        id,
                        name,
                        secret: resp.data.team.secret,
                        members: resp.data.team.members,
                        setupComplete: resp.data.team.setupComplete
                    })
                    state.inSession = true
                    if (callback) {
                        callback()
                    }
                } else if (resp.status === "unavailable") {
                    teamStore.setTeamAsLinked(id)
                }
            })
    }

    const sessionInfo = (secret: string, callback?: Function) => {
        // console.log('sio - sessionInfo', secret)

        /* Emit events */
        socket.value?.emit('sessionInfo', secret,
            (resp: ContentResponse<Session>) => {
                console.log('sessionInfo response', resp)

                /* Handle special case 'ratelimit' (temp solution Simon) */
                if (resp.status === "ratelimit") {
                    alert(rateLimitMessage);
                }

                /* Handle response, if any */
                if (resp.status === "success") {
                    teamStore.setSession(resp.data)
                    teamStore.setInstructorSecret(secret)

                    if (callback) {
                        callback(resp.status)
                    }
                }


            })
    }

    const teamAddMember = (avatarName: string, callback?: Function) => {
        // console.log('sio - teamAddMember', avatarName)


        /* Emit events */
        socket.value?.emit('teamAddMember', avatarName,
            (resp: ContentResponse<{
                member: Member
            }
            >) => {
                // console.log('teamAddMember response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {
                    teamStore.addMember(resp.data.member)

                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const teamDeleteMember = (memberId: number) => {
        // console.log('sio - teamDeleteMember', memberId)

        /* Emit events */
        socket.value?.emit('teamDeleteMember', memberId,
            (resp: SimpleNoContentResponse) => {
                // console.log('teamDeleteMember response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {
                    teamStore.removeMember(memberId)
                }
            })
    }

    const teamGetState = () => {
        console.log('sio - teamGetState')

        /* Emit events */
        socket.value?.emit('teamGetState',
            (resp: ContentResponse<object | null>) => {
                // console.log('teamGetState response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {
                    teamStore.initTeamState(resp.data as ActivityStateObject)
                }
            })
    }

    const teamSetState = (state: object | null, callback?: Function) => {
        // console.log('sio - teamSetState', state)

        /* Emit events */
        socket.value?.emit('teamSetState', state,
            (resp: SimpleNoContentResponse) => {
                // console.log('teamSetState response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {
                    if (callback) {
                        callback()
                    }
                }
            })
    }

    const teamSetSetupComplete = (callback?: Function) => {
        // console.log('sio - teamSetSetupComplete')

        /* Emit events */
        socket.value?.emit('teamSetSetupComplete',
            (resp: SimpleNoContentResponse) => {
                // console.log('teamSetSetupComplete response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {

                    teamStore.setTeamSetupComplete(true)
                    if (callback)
                        callback()
                }
            })
    }

    const teamSetActiveSlotComplete = (callback?: Function) => {
        console.log('sio - teamSetActiveSlotComplete')

        /* Emit events */
        socket.value?.emit('teamSetActiveSlotComplete',
            (resp: SimpleNoContentResponse) => {
                console.log('teamSetActiveSlotComplete response', resp)

                /* Handle response, if any */
                if (resp.status === "success") {
                    if (callback)
                        callback()
                }
            })
    }

    return { socket, state, socketConnect, sessionJoin, sessionInfo, sessionReset, teamAddMember, teamDeleteMember, teamGetState, teamSetState, teamSetSetupComplete, teamSetActiveSlotComplete } ;
}


