import { useAssets } from "~/composables/assets";

export default defineNuxtPlugin( ({}) => {
    console.log('defineNuxtPlugin - $getImage & $allImages')

    const { getImage, allImages } = useAssets()

    const imageAssets: {[p:string] : any} = allImages()

    return {
        provide: {
            getImage,
            imageAssets
        }
    }
})