<script setup lang='ts'>
import { storeToRefs } from 'pinia'
import {useSlideshowStore} from "~/stores/slideshow";

const courseStore = useCourseStore()
const { teamContent, instructorContent, currentSlot, activityState, activityStateIndex } = storeToRefs(courseStore)

// const teamStore = useTeamStore()
// const {  } = storeToRefs(teamStore)

const slideshowStore = useSlideshowStore()
const { slideIndex } = storeToRefs(slideshowStore)

const runtimeConfig = useRuntimeConfig()



</script>

<template>
  <div class="center-center-all">
  <AppWrap>
    <VitePwaManifest />
    <NuxtLayout>
      <NuxtLoadingIndicator/>
      <NuxtPage/>
    </NuxtLayout>
    <div v-if="runtimeConfig.public.dev && currentSlot !== undefined" class="debug-state">
      slot: {{currentSlot?.label}} -
      <span v-if="teamContent">{{ teamContent?.label }}</span>
      <span v-else>{{ instructorContent?.label }}</span>
      |
      activity: {{ activityState }} + index: {{ activityStateIndex }} | slide: {{ slideIndex }}
    </div>
  </AppWrap>
  </div>
</template>

<style>
.center-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.debug-state {
  color: #ccc;
  position: fixed;
  bottom: 30px;
  left: 30px;
  font-size: 20px;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
</style>
