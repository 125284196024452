//
export default defineNuxtPlugin( ({}) => {
    if (window.location.search.includes('ss')) {
        // Set saved data from sessionStorage
        sessionStorage.setItem("ss", "enabled");
        console.log('storageType plugin setting ss in sessionStorage')
    }

    // Get saved data from sessionStorage
    const ss = sessionStorage.getItem("ss");

    if (ss === 'enabled') {
        // Clear saved data from localStorage
        localStorage.clear();
    }
    console.log('storageType plugin - sessionStorage enabled:', ss === 'enabled')
})