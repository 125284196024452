import { default as index7y8pLuKuGeMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/index.vue?macro=true";
import { default as linkTeamsBEl9hdQ5o1Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/linkTeams.vue?macro=true";
import { default as loginAriF8AKZO7Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/login.vue?macro=true";
import { default as reconnecting0qNehdoQuTMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/reconnecting.vue?macro=true";
import { default as selectProgramDH5VG17Ie4Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/selectProgram.vue?macro=true";
import { default as breakdLds0UgomeMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/break.vue?macro=true";
import { default as endd5XoFLCeKcMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/end.vue?macro=true";
import { default as evaluationQAddhobHdMMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/evaluation.vue?macro=true";
import { default as indexIWuZwBWZVqMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/index.vue?macro=true";
import { default as locationsr05qUKymNtMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/locations.vue?macro=true";
import { default as observeCqgwfraCrrMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/observe.vue?macro=true";
import { default as slideshowlyHxfHRX4QMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/slideshow.vue?macro=true";
import { default as starttOW3Nm7NXnMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/start.vue?macro=true";
import { default as waitXRBY5iYE5oMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/wait.vue?macro=true";
import { default as slideshows0PETOc5yCyMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/preview/slideshows.vue?macro=true";
import { default as selectMode3jg1pu2n5BMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/selectMode.vue?macro=true";
import { default as enterCodep6LJHusCZ5Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/enterCode.vue?macro=true";
import { default as reconnectingPxnCPBTEHIMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/reconnecting.vue?macro=true";
import { default as selectTeamuaUVS1K5DbMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/selectTeam.vue?macro=true";
import { default as countdownLo7HHCMymwMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/countdown.vue?macro=true";
import { default as indexm8iVgYj4jvMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/index.vue?macro=true";
import { default as questionEnQBeMTGnEMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/question.vue?macro=true";
import { default as questionOnScreenlUippbciavMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/questionOnScreen.vue?macro=true";
import { default as endH8TZqFpe5tMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/end.vue?macro=true";
import { default as indexTPd4CsXajCMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/index.vue?macro=true";
import { default as membersoGiuenILn8Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/members.vue?macro=true";
import { default as endQJ7flRnlmIMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/end.vue?macro=true";
import { default as executorIxzGwJ6yY4Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/executor.vue?macro=true";
import { default as indexRxPvj4RuXOMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/index.vue?macro=true";
import { default as judgeNrhED4BglzMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/judge.vue?macro=true";
import { default as observertyPFRiRe3YMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/observer.vue?macro=true";
import { default as roundsZ3QGAabowdMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/rounds.vue?macro=true";
import { default as victimUQqz2El3iZMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/victim.vue?macro=true";
import { default as slideshowTcyznBQkafMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/slideshow.vue?macro=true";
import { default as start6JYeXsGC3BMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/start.vue?macro=true";
import { default as toLocationZA9ZKWl1puMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/toLocation.vue?macro=true";
import { default as wait2rsJnT1dzZMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/wait.vue?macro=true";
import { default as slideshowsTTaR7olDVQMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/slideshows.vue?macro=true";
import { default as wakeLockCcCwkw0blgMeta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/wakeLock.vue?macro=true";
import { default as webNotificationE2UBTPaFq5Meta } from "/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/webNotification.vue?macro=true";
export default [
  {
    name: index7y8pLuKuGeMeta?.name ?? "index___nl",
    path: index7y8pLuKuGeMeta?.path ?? "/",
    meta: index7y8pLuKuGeMeta || {},
    alias: index7y8pLuKuGeMeta?.alias || [],
    redirect: index7y8pLuKuGeMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: linkTeamsBEl9hdQ5o1Meta?.name ?? "instructor-bootstrap-linkTeams___nl",
    path: linkTeamsBEl9hdQ5o1Meta?.path ?? "/instructor/bootstrap/linkTeams",
    meta: linkTeamsBEl9hdQ5o1Meta || {},
    alias: linkTeamsBEl9hdQ5o1Meta?.alias || [],
    redirect: linkTeamsBEl9hdQ5o1Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/linkTeams.vue").then(m => m.default || m)
  },
  {
    name: loginAriF8AKZO7Meta?.name ?? "instructor-bootstrap-login___nl",
    path: loginAriF8AKZO7Meta?.path ?? "/instructor/bootstrap/login",
    meta: loginAriF8AKZO7Meta || {},
    alias: loginAriF8AKZO7Meta?.alias || [],
    redirect: loginAriF8AKZO7Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/login.vue").then(m => m.default || m)
  },
  {
    name: reconnecting0qNehdoQuTMeta?.name ?? "instructor-bootstrap-reconnecting___nl",
    path: reconnecting0qNehdoQuTMeta?.path ?? "/instructor/bootstrap/reconnecting",
    meta: reconnecting0qNehdoQuTMeta || {},
    alias: reconnecting0qNehdoQuTMeta?.alias || [],
    redirect: reconnecting0qNehdoQuTMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/reconnecting.vue").then(m => m.default || m)
  },
  {
    name: selectProgramDH5VG17Ie4Meta?.name ?? "instructor-bootstrap-selectProgram___nl",
    path: selectProgramDH5VG17Ie4Meta?.path ?? "/instructor/bootstrap/selectProgram",
    meta: selectProgramDH5VG17Ie4Meta || {},
    alias: selectProgramDH5VG17Ie4Meta?.alias || [],
    redirect: selectProgramDH5VG17Ie4Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/bootstrap/selectProgram.vue").then(m => m.default || m)
  },
  {
    name: breakdLds0UgomeMeta?.name ?? "instructor-break___nl",
    path: breakdLds0UgomeMeta?.path ?? "/instructor/break",
    meta: breakdLds0UgomeMeta || {},
    alias: breakdLds0UgomeMeta?.alias || [],
    redirect: breakdLds0UgomeMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/break.vue").then(m => m.default || m)
  },
  {
    name: endd5XoFLCeKcMeta?.name ?? "instructor-end___nl",
    path: endd5XoFLCeKcMeta?.path ?? "/instructor/end",
    meta: endd5XoFLCeKcMeta || {},
    alias: endd5XoFLCeKcMeta?.alias || [],
    redirect: endd5XoFLCeKcMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/end.vue").then(m => m.default || m)
  },
  {
    name: evaluationQAddhobHdMMeta?.name ?? "instructor-evaluation___nl",
    path: evaluationQAddhobHdMMeta?.path ?? "/instructor/evaluation",
    meta: evaluationQAddhobHdMMeta || {},
    alias: evaluationQAddhobHdMMeta?.alias || [],
    redirect: evaluationQAddhobHdMMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/evaluation.vue").then(m => m.default || m)
  },
  {
    name: indexIWuZwBWZVqMeta?.name ?? "instructor___nl",
    path: indexIWuZwBWZVqMeta?.path ?? "/instructor",
    meta: indexIWuZwBWZVqMeta || {},
    alias: indexIWuZwBWZVqMeta?.alias || [],
    redirect: indexIWuZwBWZVqMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/index.vue").then(m => m.default || m)
  },
  {
    name: locationsr05qUKymNtMeta?.name ?? "instructor-locations___nl",
    path: locationsr05qUKymNtMeta?.path ?? "/instructor/locations",
    meta: locationsr05qUKymNtMeta || {},
    alias: locationsr05qUKymNtMeta?.alias || [],
    redirect: locationsr05qUKymNtMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/locations.vue").then(m => m.default || m)
  },
  {
    name: observeCqgwfraCrrMeta?.name ?? "instructor-observe___nl",
    path: observeCqgwfraCrrMeta?.path ?? "/instructor/observe",
    meta: observeCqgwfraCrrMeta || {},
    alias: observeCqgwfraCrrMeta?.alias || [],
    redirect: observeCqgwfraCrrMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/observe.vue").then(m => m.default || m)
  },
  {
    name: slideshowlyHxfHRX4QMeta?.name ?? "instructor-slideshow___nl",
    path: slideshowlyHxfHRX4QMeta?.path ?? "/instructor/slideshow",
    meta: slideshowlyHxfHRX4QMeta || {},
    alias: slideshowlyHxfHRX4QMeta?.alias || [],
    redirect: slideshowlyHxfHRX4QMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/slideshow.vue").then(m => m.default || m)
  },
  {
    name: starttOW3Nm7NXnMeta?.name ?? "instructor-start___nl",
    path: starttOW3Nm7NXnMeta?.path ?? "/instructor/start",
    meta: starttOW3Nm7NXnMeta || {},
    alias: starttOW3Nm7NXnMeta?.alias || [],
    redirect: starttOW3Nm7NXnMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/start.vue").then(m => m.default || m)
  },
  {
    name: waitXRBY5iYE5oMeta?.name ?? "instructor-wait___nl",
    path: waitXRBY5iYE5oMeta?.path ?? "/instructor/wait",
    meta: waitXRBY5iYE5oMeta || {},
    alias: waitXRBY5iYE5oMeta?.alias || [],
    redirect: waitXRBY5iYE5oMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/instructor/wait.vue").then(m => m.default || m)
  },
  {
    name: slideshows0PETOc5yCyMeta?.name ?? "preview-slideshows___nl",
    path: slideshows0PETOc5yCyMeta?.path ?? "/preview/slideshows",
    meta: slideshows0PETOc5yCyMeta || {},
    alias: slideshows0PETOc5yCyMeta?.alias || [],
    redirect: slideshows0PETOc5yCyMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/preview/slideshows.vue").then(m => m.default || m)
  },
  {
    name: selectMode3jg1pu2n5BMeta?.name ?? "selectMode___nl",
    path: selectMode3jg1pu2n5BMeta?.path ?? "/selectMode",
    meta: selectMode3jg1pu2n5BMeta || {},
    alias: selectMode3jg1pu2n5BMeta?.alias || [],
    redirect: selectMode3jg1pu2n5BMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/selectMode.vue").then(m => m.default || m)
  },
  {
    name: enterCodep6LJHusCZ5Meta?.name ?? "team-bootstrap-enterCode___nl",
    path: enterCodep6LJHusCZ5Meta?.path ?? "/team/bootstrap/enterCode",
    meta: enterCodep6LJHusCZ5Meta || {},
    alias: enterCodep6LJHusCZ5Meta?.alias || [],
    redirect: enterCodep6LJHusCZ5Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/enterCode.vue").then(m => m.default || m)
  },
  {
    name: reconnectingPxnCPBTEHIMeta?.name ?? "team-bootstrap-reconnecting___nl",
    path: reconnectingPxnCPBTEHIMeta?.path ?? "/team/bootstrap/reconnecting",
    meta: reconnectingPxnCPBTEHIMeta || {},
    alias: reconnectingPxnCPBTEHIMeta?.alias || [],
    redirect: reconnectingPxnCPBTEHIMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/reconnecting.vue").then(m => m.default || m)
  },
  {
    name: selectTeamuaUVS1K5DbMeta?.name ?? "team-bootstrap-selectTeam___nl",
    path: selectTeamuaUVS1K5DbMeta?.path ?? "/team/bootstrap/selectTeam",
    meta: selectTeamuaUVS1K5DbMeta || {},
    alias: selectTeamuaUVS1K5DbMeta?.alias || [],
    redirect: selectTeamuaUVS1K5DbMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/bootstrap/selectTeam.vue").then(m => m.default || m)
  },
  {
    name: countdownLo7HHCMymwMeta?.name ?? "team-cardGame-countdown___nl",
    path: countdownLo7HHCMymwMeta?.path ?? "/team/cardGame/countdown",
    meta: countdownLo7HHCMymwMeta || {},
    alias: countdownLo7HHCMymwMeta?.alias || [],
    redirect: countdownLo7HHCMymwMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/countdown.vue").then(m => m.default || m)
  },
  {
    name: indexm8iVgYj4jvMeta?.name ?? "team-cardGame___nl",
    path: indexm8iVgYj4jvMeta?.path ?? "/team/cardGame",
    meta: indexm8iVgYj4jvMeta || {},
    alias: indexm8iVgYj4jvMeta?.alias || [],
    redirect: indexm8iVgYj4jvMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/index.vue").then(m => m.default || m)
  },
  {
    name: questionEnQBeMTGnEMeta?.name ?? "team-cardGame-question___nl",
    path: questionEnQBeMTGnEMeta?.path ?? "/team/cardGame/question",
    meta: questionEnQBeMTGnEMeta || {},
    alias: questionEnQBeMTGnEMeta?.alias || [],
    redirect: questionEnQBeMTGnEMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/question.vue").then(m => m.default || m)
  },
  {
    name: questionOnScreenlUippbciavMeta?.name ?? "team-cardGame-questionOnScreen___nl",
    path: questionOnScreenlUippbciavMeta?.path ?? "/team/cardGame/questionOnScreen",
    meta: questionOnScreenlUippbciavMeta || {},
    alias: questionOnScreenlUippbciavMeta?.alias || [],
    redirect: questionOnScreenlUippbciavMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/cardGame/questionOnScreen.vue").then(m => m.default || m)
  },
  {
    name: endH8TZqFpe5tMeta?.name ?? "team-end___nl",
    path: endH8TZqFpe5tMeta?.path ?? "/team/end",
    meta: endH8TZqFpe5tMeta || {},
    alias: endH8TZqFpe5tMeta?.alias || [],
    redirect: endH8TZqFpe5tMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/end.vue").then(m => m.default || m)
  },
  {
    name: indexTPd4CsXajCMeta?.name ?? "team___nl",
    path: indexTPd4CsXajCMeta?.path ?? "/team",
    meta: indexTPd4CsXajCMeta || {},
    alias: indexTPd4CsXajCMeta?.alias || [],
    redirect: indexTPd4CsXajCMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/index.vue").then(m => m.default || m)
  },
  {
    name: membersoGiuenILn8Meta?.name ?? "team-members___nl",
    path: membersoGiuenILn8Meta?.path ?? "/team/members",
    meta: membersoGiuenILn8Meta || {},
    alias: membersoGiuenILn8Meta?.alias || [],
    redirect: membersoGiuenILn8Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/members.vue").then(m => m.default || m)
  },
  {
    name: endQJ7flRnlmIMeta?.name ?? "team-observe-end___nl",
    path: endQJ7flRnlmIMeta?.path ?? "/team/observe/end",
    meta: endQJ7flRnlmIMeta || {},
    alias: endQJ7flRnlmIMeta?.alias || [],
    redirect: endQJ7flRnlmIMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/end.vue").then(m => m.default || m)
  },
  {
    name: executorIxzGwJ6yY4Meta?.name ?? "team-observe-executor___nl",
    path: executorIxzGwJ6yY4Meta?.path ?? "/team/observe/executor",
    meta: executorIxzGwJ6yY4Meta || {},
    alias: executorIxzGwJ6yY4Meta?.alias || [],
    redirect: executorIxzGwJ6yY4Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/executor.vue").then(m => m.default || m)
  },
  {
    name: indexRxPvj4RuXOMeta?.name ?? "team-observe___nl",
    path: indexRxPvj4RuXOMeta?.path ?? "/team/observe",
    meta: indexRxPvj4RuXOMeta || {},
    alias: indexRxPvj4RuXOMeta?.alias || [],
    redirect: indexRxPvj4RuXOMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/index.vue").then(m => m.default || m)
  },
  {
    name: judgeNrhED4BglzMeta?.name ?? "team-observe-judge___nl",
    path: judgeNrhED4BglzMeta?.path ?? "/team/observe/judge",
    meta: judgeNrhED4BglzMeta || {},
    alias: judgeNrhED4BglzMeta?.alias || [],
    redirect: judgeNrhED4BglzMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/judge.vue").then(m => m.default || m)
  },
  {
    name: observertyPFRiRe3YMeta?.name ?? "team-observe-observer___nl",
    path: observertyPFRiRe3YMeta?.path ?? "/team/observe/observer",
    meta: observertyPFRiRe3YMeta || {},
    alias: observertyPFRiRe3YMeta?.alias || [],
    redirect: observertyPFRiRe3YMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/observer.vue").then(m => m.default || m)
  },
  {
    name: roundsZ3QGAabowdMeta?.name ?? "team-observe-rounds___nl",
    path: roundsZ3QGAabowdMeta?.path ?? "/team/observe/rounds",
    meta: roundsZ3QGAabowdMeta || {},
    alias: roundsZ3QGAabowdMeta?.alias || [],
    redirect: roundsZ3QGAabowdMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/rounds.vue").then(m => m.default || m)
  },
  {
    name: victimUQqz2El3iZMeta?.name ?? "team-observe-victim___nl",
    path: victimUQqz2El3iZMeta?.path ?? "/team/observe/victim",
    meta: victimUQqz2El3iZMeta || {},
    alias: victimUQqz2El3iZMeta?.alias || [],
    redirect: victimUQqz2El3iZMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/observe/victim.vue").then(m => m.default || m)
  },
  {
    name: slideshowTcyznBQkafMeta?.name ?? "team-slideshow___nl",
    path: slideshowTcyznBQkafMeta?.path ?? "/team/slideshow",
    meta: slideshowTcyznBQkafMeta || {},
    alias: slideshowTcyznBQkafMeta?.alias || [],
    redirect: slideshowTcyznBQkafMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/slideshow.vue").then(m => m.default || m)
  },
  {
    name: start6JYeXsGC3BMeta?.name ?? "team-start___nl",
    path: start6JYeXsGC3BMeta?.path ?? "/team/start",
    meta: start6JYeXsGC3BMeta || {},
    alias: start6JYeXsGC3BMeta?.alias || [],
    redirect: start6JYeXsGC3BMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/start.vue").then(m => m.default || m)
  },
  {
    name: toLocationZA9ZKWl1puMeta?.name ?? "team-toLocation___nl",
    path: toLocationZA9ZKWl1puMeta?.path ?? "/team/toLocation",
    meta: toLocationZA9ZKWl1puMeta || {},
    alias: toLocationZA9ZKWl1puMeta?.alias || [],
    redirect: toLocationZA9ZKWl1puMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/toLocation.vue").then(m => m.default || m)
  },
  {
    name: wait2rsJnT1dzZMeta?.name ?? "team-wait___nl",
    path: wait2rsJnT1dzZMeta?.path ?? "/team/wait",
    meta: wait2rsJnT1dzZMeta || {},
    alias: wait2rsJnT1dzZMeta?.alias || [],
    redirect: wait2rsJnT1dzZMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/team/wait.vue").then(m => m.default || m)
  },
  {
    name: slideshowsTTaR7olDVQMeta?.name ?? "test-slideshows___nl",
    path: slideshowsTTaR7olDVQMeta?.path ?? "/test/slideshows",
    meta: slideshowsTTaR7olDVQMeta || {},
    alias: slideshowsTTaR7olDVQMeta?.alias || [],
    redirect: slideshowsTTaR7olDVQMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/slideshows.vue").then(m => m.default || m)
  },
  {
    name: wakeLockCcCwkw0blgMeta?.name ?? "test-wakeLock___nl",
    path: wakeLockCcCwkw0blgMeta?.path ?? "/test/wakeLock",
    meta: wakeLockCcCwkw0blgMeta || {},
    alias: wakeLockCcCwkw0blgMeta?.alias || [],
    redirect: wakeLockCcCwkw0blgMeta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/wakeLock.vue").then(m => m.default || m)
  },
  {
    name: webNotificationE2UBTPaFq5Meta?.name ?? "test-webNotification___nl",
    path: webNotificationE2UBTPaFq5Meta?.path ?? "/test/webNotification",
    meta: webNotificationE2UBTPaFq5Meta || {},
    alias: webNotificationE2UBTPaFq5Meta?.alias || [],
    redirect: webNotificationE2UBTPaFq5Meta?.redirect || undefined,
    component: () => import("/home/jenkins/workspace/g4s_bhv_app/frontend/pages/test/webNotification.vue").then(m => m.default || m)
  }
]