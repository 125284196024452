<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()


</script>
<template>
    <div class="app-wrap" >
      <slot></slot>
      <div class="buildAdditionalInfo">version: {{ runtimeConfig.public.buildAdditionalInfo }}</div>
    </div>
</template>

<style lang="css" >
@import "normalize.css/normalize.css";
@import "../assets/styles/global.css";
</style>

<style lang="css" scoped>
@import "@/assets/styles/main.css";

.buildAdditionalInfo {
  font-size: 10px;
  font-family: "Arial", sans-serif;
  position: absolute;
  top: 785px;
  right: 5px;
}
</style>

