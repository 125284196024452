import { filename } from 'pathe/utils'
export function useAssets() {
    function getImage(imageName: string) {
        // console.log('useAssets', imageName)
        let assets
        if (/(\.svg)$/i.exec(imageName)) assets = import.meta.glob('~/assets/images/**/*.svg', {eager: true})
        else if (/(\.png)$/i.exec(imageName)) assets = import.meta.glob('~/assets/images/**/*.png', {eager: true})
        else assets = import.meta.glob('~/assets/images/**/*.jpg', {eager: true})

        const fileName = filename(imageName)
        // console.log('useAssets fileName', fileName)
        const images = Object.fromEntries(
            Object.entries(assets).map(([key, value]) => [filename(key), (value as Record<string, any>).default]),
        )
        return images[fileName]
    }

     const allImages = () => {
        const assets = import.meta.glob('~/assets/images/**/*.(svg|png|jpg|jpeg)', { eager: true })
        return Object.fromEntries(
            Object.entries(assets).map(([key, value]) => [filename(key), (value as Record<string, any>).default]),
        )
    }

    return {
        getImage, allImages
    }
}

// export const useAssets = () => {
//     // Images
//     const images = computed<Record<string, { default: string }>>(() => import.meta.glob('~/assets/images/**/*.(png|jpg|jpeg|svg)', { eager: true }));
//     const getImage = (src: string): string | undefined => {
//         for (const path in images.value) {
//             const image = images.value[path].default;
//             if (path.endsWith(`assets/images/${src}`)) return image;
//         }
//         return undefined;
//     };
//
//     const allImages = () => {
//         const assets = import.meta.glob('~/assets/images/**/*.(svg|png|jpg|jpeg)', { eager: true })
//         return Object.fromEntries(
//             Object.entries(assets).map(([key, value]) => [filename(key), (value as Record<string, any>).default]),
//         )
//     }
//
//     return {
//         getImage, allImages
//     };
// };

// export default useAssets;

